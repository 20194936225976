


































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";

interface dataBusqueda {
  dataDocumentos: Array<any> | null;
  dataNoticias: Array<any> | null;
  dataUnidades: Array<any> | null;
  dataLinks: Array<any> | null;
}

@Component
export default class Buscador extends Vue {
  private win = window;
  private busquedaData: any = [];
  private links: any = null;

  @Watch("$store.state.modalBusqueda")
  private watchModalBusqueda(newValue: any) {
    if (!newValue) {
      this.cerrar();
    }
  }

  @Watch("$store.state.textBusqueda")
  private watchInput(newValue: string) {
    if (newValue && newValue !== "") {
      this.buscar(newValue);
    } else {
      this.busquedaData = null;
    }
  }

  get fontExterno() {
    if (this.$q.screen.xs) {
      return "15";
    }
    return "20";
  }
  get fontTitulo() {
    if (this.$q.screen.xs) {
      return "35";
    }
    return "40";
  }
  get fontTexto() {
    if (this.$q.screen.xs) {
      return "11";
    }
    return "16";
  }
  private mounted() {
    this.busquedaData = [];
    // this.busquedaData2 = [];

    this.links = null;
  }

  @Debounce(500)
  private buscar(value: any) {
    if (value && value != "" && value.trim().length >= 2) {
      this.$axios
        .get("buscador?text=" + value)
        .then((res: any) => {
          let base = process.env.VUE_APP_BASE_URL;
          this.busquedaData = res.data;
          // console.log(res.data);

          for (let i = 0; i < this.busquedaData.dataGalerias.length; i++) {
            if (
              this.busquedaData.dataGalerias[i].imagen_preview &&
              this.busquedaData.dataGalerias[i].imagen_preview != ""
            ) {
              this.busquedaData.dataGalerias[i].imagen_preview =
                base + this.busquedaData.dataGalerias[i].imagen_preview;
            }
          }

          this.links = [];
          for (let i = 0; i < this.busquedaData.dataLinks.length; i++) {
            const link = this.busquedaData.dataLinks[i];
            let existe = false;
            for (let j = 0; j < this.links.length; j++) {
              if (this.links[j].nombre == link.nombre) {
                existe = true;
              }
            }
            if (!existe) {
              this.links.push(link);
            }
          }

          for (let i = 0; i < this.busquedaData.dataPaginas.length; i++) {
            const pagina = this.busquedaData.dataPaginas[i];
            let existe = false;
            for (let j = 0; j < this.links.length; j++) {
              if (this.links[j].nombre_web == pagina.nombre_web) {
                existe = true;
              }
            }
            if (!existe) {
              this.links.push({
                id: pagina.id,
                nombre: pagina.titulo,
                url: "",
                tipo: 1,
                nombre_web: pagina.nombre_web
              });
            }
          }
          this.links.sort(this.compare);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  private cerrar() {
    this.$store.commit("SET_MODAL_BUSCADOR", false);
    this.busquedaData = null;
  }

  private irNoticia(nombreWeb: any) {
    console.log(nombreWeb);
    if (nombreWeb && nombreWeb != "") {
      if (
        this.$router.currentRoute.name == "Publicacion" &&
        this.$router.currentRoute.params.nombre &&
        this.$router.currentRoute.params.nombre == nombreWeb
      ) {
        this.$store.commit("SET_MODAL_BUSCADOR", false);
        this.$router.go(0);
      } else {
        if (this.$router.currentRoute.name == "Publicacion") {
          if (
            this.$router.currentRoute.params.nombre &&
            this.$router.currentRoute.params.nombre != nombreWeb
          ) {
            this.$store.commit("SET_MODAL_BUSCADOR", false);
            this.$router
              .push({ name: "Publicacion", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
            this.$router.go(0);
          } else {
            this.$store.commit("SET_MODAL_BUSCADOR", false);
            this.$router
              .push({ name: "Publicacion", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        } else {
          this.$store.commit("SET_MODAL_BUSCADOR", false);
          this.$router
            .push({ name: "Publicacion", params: { nombre: nombreWeb } })
            .catch(err => {
              console.log(err);
            });
        }
      }
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "contactos"
    ) {
      this.routerGo("Telefonos");
    } else if (
      nombreWeb == "organigrama-municipal" ||
      nombreWeb == "organigrama"
    ) {
      this.routerGo("Organigrama");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private irGaleria(nombreWeb: any) {
    if (nombreWeb && nombreWeb != "") {
      if (
        this.$router.currentRoute.name == "DetalleGaleria" &&
        this.$router.currentRoute.params.nombre &&
        this.$router.currentRoute.params.nombre == nombreWeb
      ) {
        this.$router.go(0);
      } else {
        if (this.$router.currentRoute.name == "DetalleGaleria") {
          if (
            this.$router.currentRoute.params.nombre &&
            this.$router.currentRoute.params.nombre != nombreWeb
          ) {
            this.$router
              .push({ name: "DetalleGaleria", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
            this.$router.go(0);
          } else {
            this.$router
              .push({ name: "DetalleGaleria", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        } else {
          this.$router
            .push({ name: "DetalleGaleria", params: { nombre: nombreWeb } })
            .catch(err => {
              console.log(err);
            });
        }
      }
    }
  }

  private compare(a, b) {
    if (a.nombre < b.nombre) {
      return -1;
    }
    if (a.nombre > b.nombre) {
      return 1;
    }
    return 0;
  }

  private descargarDocumento(archivo) {
    window.open(process.env.VUE_APP_BASE_URL + archivo, "_blank");
  }
}
