









































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Notify } from "quasar";
import Buscador from "@/components/Common/Buscador.vue";
@Component({
  components: {
    Buscador,
  },
})
export default class HeaderComponent extends Vue {
  private text = "";
  private left = false;
  private win = window;
  private menu = "";
  private link = "";
  private escondido = true;
  private marcadormenu: any = "";

  private mounted() {}

  private beforeMount() {
    this.getRuntimeConfig();
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch((err) => {
          console.log(err);
        });
      }
    }
  }

  private async getRuntimeConfig() {
    const runtimeConfig1 = await fetch("/menu.json");
    this.menu = await runtimeConfig1.json();
    // console.log(this.menu);
    // for (let i = 0; i < this.menu.length; i++) {
    //   this.menu[i].id = i + 200;
    // }

    // console.log(this.menu);
  }

  private toLink(item: any, blank: any) {
    if (item && item != "") {
      if (blank == 1) {
        window.open(item, "_blank");
      } else {
        window.open(item, "_self");
      }
    } else {
      return;
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
      this.routerGo("ActasConcejo");
    } else if (nombreWeb == "concejo" || nombreWeb == "concejo-municipal") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "telefonos-oficinas"
    ) {
      this.routerGo("Telefonos");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch((err) => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
    }
  }

  // MENU

  private verId(id) {
    if (this.marcadormenu != id && this.marcadormenu != "") {
      // console.log("menuanterior", this.marcadormenu);
      let menuanterior: any = document.getElementById(this.marcadormenu);
      menuanterior.classList.toggle("menu_off");
      let menu: any = document.getElementById(id);
      menu.classList.toggle("menu_off");
      this.marcadormenu = id;
    } else if (this.marcadormenu == id) {
      // console.log("mismo id");
      let menu: any = document.getElementById(id);
      menu.classList.toggle("menu_off");
      this.marcadormenu = "";
    } else {
      // console.log("anterior vacio");
      let menu: any = document.getElementById(id);
      // console.log(id);
      menu.classList.toggle("menu_off");
      this.marcadormenu = id;
    }
  }
}
